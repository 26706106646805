import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Row} from "react-bootstrap";
import {ParallaxProvider, Parallax} from "react-scroll-parallax";

import joinPhone from '../../../assets/img/main/join/main-join-phone.png';
import joinPhoneBg from '../../../assets/img/main/join/main-join-phone-bg.svg';
import joinBg from '../../../assets/img/main/join/main-join-bg.svg';
import joinBallOne from "../../../assets/img/main/join/main-join-ball-1.png";
import joinBallTwo from "../../../assets/img/main/join/main-join-ball-2.png";
import joinBallThree from "../../../assets/img/main/join/main-join-ball-3.png";
import joinBallFour from "../../../assets/img/main/join/main-join-ball-4.png";
import joinBallFive from "../../../assets/img/main/join/main-join-ball-5.png";

const Join = ({isMdScreen}) => {
    return (
        <ParallaxProvider>
            <section id="main-join" className="main-join-section">
                <div className="bg-image-wrap">
                    <img src={joinBg} alt="Bg"/>
                </div>
                <Container>
                    <Row>
                        <div className="balls-wrap">
                            <Parallax speed={isMdScreen ? 2 : 4} className="parallax ball ball-1">
                                <img src={joinBallOne} alt="Ball"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? 5 : 10} className="parallax ball ball-2">
                                <img src={joinBallTwo} alt="Ball"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? -4 : -8} className="parallax ball ball-3">
                                <img src={joinBallThree} alt="Ball"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? -2 : -4} className="parallax ball ball-4">
                                <img src={joinBallFour} alt="Ball"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? 1 : 2} className="parallax ball ball-5">
                                <img src={joinBallFive} alt="Ball"/>
                            </Parallax>
                        </div>
                        <div className="text-wrap">
                            <h3>Join Telegram<br/> and start today!</h3>
                        </div>
                        <div className="img-wrap">
                            <img src={joinPhone} alt="Phone"/>
                            <div className="bg-wrap">
                                <img src={joinPhoneBg} alt="Phone Bg"/>
                            </div>
                        </div>
                        <div className="cta-wrap">
                            <a href="https://t.me/Oxtools1_bot" className="btn-main btn-gradient" target="_blank" rel="noreferrer">
                                <span className="bg"></span>
                                <span className="text">Access Bot</span>
                            </a>
                        </div>
                    </Row>
                </Container>
            </section>
        </ParallaxProvider>
    )
}

export default Join;
