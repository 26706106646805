import React from "react";
import { Container, Row } from "react-bootstrap";
import "../../../assets/scss/dapp/connect.css"

const Hero = () => {

  return (
    <section id="dapp-hero" className="dapp-hero-section">
      <Container>
        <Row>
          <div className="headline-wrap">
            <h4>DAPP (Coming soon)</h4>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
