import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Main from "./components/Main";
import MultipleBuyers from "./components/MultipleBuyers";
import Snipers from "./components/SnipersList";
import Simulation from "./components/Simulation";
import Gitbook from "./components/Gitbook";
import NotFound from "./components/NotFound";
import "./App.scss";

const App = () => {
  const appHeightHandle = () => {
    document
      .querySelector("body")
      .style.setProperty("--app-height", window.innerHeight + "px");
  };

  appHeightHandle();

  window.addEventListener("resize", () => {
    if (window.innerWidth < 1200) {
      appHeightHandle();
    }
  });

  return (
    <>
          <Router>
            <Switch>
              <Route exact path="/" component={Main} />
              <Route exact path="/multipleBuyers/:userId" component={MultipleBuyers} />
              <Route exact path="/snipers/:userId" component={Snipers} />
              <Route exact path="/simulation/:userId" component={Simulation} />
              <Route exact path="/dapp" component={Gitbook} />
              <Route component={NotFound} />
            </Switch>
          </Router>
    </>
  );
};

export default App;
