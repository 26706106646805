import React, { useState, useEffect, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col, Spinner, Alert, Button, Form } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.min.css";

const CsvDisplay = () => {
  const { userId } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState('All');
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!userId) {
        setError("No user ID provided");
        setLoading(false);
        return;
      }

      try {
        const apiUrl = `https://www.0xtools.app/api/getData?userId=${userId}&route=simulation`;
        const response = await fetch(apiUrl);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const text = await response.text();
        const rows = text.split("\n");
        const headers = rows[0].split(",");

        const parsedData = rows.slice(1).filter(row => row.trim()).map(row => {
          const values = row.split(",");
          return headers.reduce((obj, header, index) => {
            obj[header.trim()] = values[index]?.trim() || "";
            return obj;
          }, {});
        });

        setData(parsedData);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleCellClick = (event) => {
    navigator.clipboard.writeText(event.value).catch(err => console.error("Failed to copy text"));
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = event.target.value === "All" ? data.length : parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    if (gridApi) {
      gridApi.paginationSetPageSize(newPageSize);
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
  };

  const filteredData = data.filter(row =>
    Object.values(row).some(value => value.toString().toLowerCase().includes(search.toLowerCase()))
  );

  const columnDefs = useMemo(() => {
    return data.length > 0
      ? Object.keys(data[0]).map(key => ({
          headerName: key,
          field: key,
          sortable: true,
          filter: true,
          resizable: true,
          flex: 1,
        }))
      : [];
  }, [data]);

  if (!userId) {
    return (
      <Container className="py-5">
        <Alert variant="danger">User ID is required</Alert>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <section id="list-hero" className="list-hero-section">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} className="list-wrap">
            <div className="headline-wrap d-flex justify-content-between align-items-center">
              <h4 className="text-white mb-0">Simulation Data</h4>
              <Button variant="outline-light" size="sm" as={Link} to="/">
                Home
              </Button>
            </div>
            <div className="d-flex gap-3 mb-3">
              <Form.Control
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
                className="search-input"
              />
              <Form.Select 
                value={pageSize} 
                onChange={handlePageSizeChange}
                style={{ width: 'auto' }}
              >
                <option value="20">20 rows</option>
                <option value="50">50 rows</option>
                <option value="100">100 rows</option>
                <option value="All">All rows</option>
              </Form.Select>
            </div>
            <div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }}>
              <AgGridReact
                rowData={filteredData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={pageSize}
                onCellClicked={handleCellClick}
                onGridReady={onGridReady}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CsvDisplay;