import React from "react";
import { Container, Row } from "react-bootstrap";

const Ready = () => {
  return (
    <section id="main-ready" className="main-ready-section">
      <Container>
        <Row>
          <div className="text-wrap">
            <h5>Subscribe today</h5>
            <h2>Ready to get started?</h2>
            <div className="sub-wrap">
              <p>
                Subscribe now to get
                <br /> access to the bot!
              </p>
            </div>
          </div>
          <div className="card-wrap">
            <h4>Subscription</h4>
            <p>Get access to all our tools in just a few clicks.</p>
            <ul>
              <li>Fast and Secure</li>
              <li>All-in-one tool</li>
              <li>Non-automatic renewal</li>
              <li>A great help for traders/degens</li>
              <li>Affordable prices</li>
              <li>Revenue sharing</li>
            </ul>
            <div className="price-wrap">
              <h4>
                0.2 ETH<span>/month</span>
              </h4>
            </div>
            <a
              href="https://t.me/Oxtools1_bot"
              className="btn-main btn-gradient"
              target="_blank" rel="noreferrer"
            >
              <span className="bg"></span>
              <span className="text">Get started</span>
            </a>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Ready;
