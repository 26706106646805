import React from 'react';

import ScrollToTopOnMount from "../General/ScrollToTopOnMount";
import Header from "../General/Header";
import Lines from "../General/Lines";

import Hero from "./Hero";

const Simulation = () => {
    return (
        <>
            <ScrollToTopOnMount/>
            <Header page="Simulation"/>
            <main>
                <Lines/>
                <Hero/>
            </main>
        </>
    )
}

export default Simulation;
