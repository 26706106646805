import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import copyAllIcon from "../../../assets/img/general/copy-all-icon.svg";

const AccordionItem = ({ title, content }) => {
  return (
    <Accordion.Item eventKey={title}>
      <Accordion.Header>
        <p>{title}</p>
      </Accordion.Header>
      <Accordion.Body>
        <div className="addresses-wrap">
          <ul>
            {content.map((address, index) => (
              <li key={index}>{address}</li>
            ))}
          </ul>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const Hero = () => {
  const [data, setData] = useState(null);
  const { userId } = useParams();

  useEffect(() => {
    const apiUrl = `https://www.0xtools.app/api/getData?userId=${userId}&route=snipers`;

    axios
      .get(apiUrl)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
}, [userId]);


  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  const copyAllData = () => {
    const allData = Object.entries(data)
      .map(([key, addresses]) => `${key}:\n${addresses.join("\n")}`)
      .join("\n\n");
    copyToClipboard(allData);
  };

  return (
    <section id="list-hero" className="list-hero-section">
      <Container>
        <Row>
          <div className="list-wrap">
            <div className="headline-wrap">
              <h4>List of all snipers</h4>
            </div>
            <div className="accordion-wrap">
              <Accordion defaultActiveKey="0">
                {data &&
                  Object.keys(data).map((key) => (
                    <AccordionItem key={key} title={key} content={data[key]} />
                  ))}
              </Accordion>
            </div>
            <div className="actions-wrap">
              <Link to="/" className="btn-main btn-gradient">
                <span className="bg"></span>
                <span className="text">Home</span>
              </Link>
              <button
                onClick={copyAllData}
                className="btn-main btn-text btn-sajuio"
              >
                <span className="text">Copy all</span>
                <span className="icon">
                  <img src={copyAllIcon} alt="Tout copier" />
                </span>
              </button>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
