import {Container, Row} from "react-bootstrap";

import logo from "../../../assets/img/general/logo.png";
import xIcon from "../../../assets/img/general/x-icon.svg";
import telegramIcon from "../../../assets/img/general/telegram-icon.svg";

const Footer = () => {
    return (
        <footer>
            <Container>
                <Row>
                    <div className="logo-wrap">
                        <img src={logo} alt="Logo"/>
                        <div className="copyright-wrap">
                            <p>© 2023 0xTools</p>
                        </div>
                    </div>
                    <div className="links-wrap">
                        <div className="column-wrap">
                            <ul>
                                <li><a href="https://0xtools.gitbook.io/0xtools/" style={{color: 'white'}} target="_blank" rel="noreferrer">Gitbook</a></li>
                                <li><a href="https://t.me/Oxtools1_bot" style={{color: 'white'}} target="_blank" rel="noreferrer">Bot</a></li>
                            </ul>
                            <ul className="socials">
                                <li>
                                    <a href="https://twitter.com/0xtools_" style={{color: 'white'}} target="_blank" rel="noreferrer">
                                        <img src={xIcon} alt="X Icon"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://t.me/OxToolserc" style={{color: 'white'}} target="_blank" rel="noreferrer">
                                        <img src={telegramIcon} alt="Telegram Icon"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
