import React from 'react';
import {useMediaQuery} from 'react-responsive';

import ScrollToTopOnMount from "../General/ScrollToTopOnMount";
import Header from "../General/Header";
import Lines from "../General/Lines";
import Footer from "../General/Footer";

import Hero from "./Hero";
import Join from "./Join";
import Utilities from "./Utilities";
import Ready from "./Ready";

const Main = () => {
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});

    return (
        <>
            <ScrollToTopOnMount/>
            <Header page="main" />
            <main>
                <Lines/>
                <Hero isMdScreen={isMdScreen}/>
                <Join isMdScreen={isMdScreen}/>
                <Utilities/>
                <Ready/>
            </main>
            <Footer/>
        </>
    )
}

export default Main;
