import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";

import logo from "../../../assets/img/general/logo.png";
import xIcon from "../../../assets/img/general/x-icon.svg";
import telegramIcon from "../../../assets/img/general/telegram-icon.svg";

const Header = ({ page }) => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  const handleTogglerClick = () => {
    document.querySelector("body").classList.toggle("navbar-opened");
  };

  const handleNavbarClose = () => {
    if (window.innerWidth < 1200) {
      document.querySelector("header .navbar .navbar-toggler").click();
      document.querySelector("body").classList.remove("navbar-opened");
    }
  };

  const navbarLinks = useMemo(() => {
    if (page === "main") {
      return [
        {
          title: "Subscription",
          href: "#main-ready",
        },
        {
          title: "Gitbook",
          href: "https://0xtools.gitbook.io/0xtools/",
          target: "_blank",
        },
      ];
    }

    if (page === "list") {
      return [
        {
          title: "Main Page",
          href: "/",
        },
        {
          title: "DApp",
          href: "/dapp",
        },
        {
          title: "Gitbook",
          href: "https://0xtools.gitbook.io/0xtools/",
          target: "_blank",
        },
      ];
    }

    if (page === "dapp") {
      return [
        {
          title: "Main Page",
          href: "/",
        },
        {
          title: "Gitbook",
          href: "https://0xtools.gitbook.io/0xtools/",
          target: "_blank",
        },
      ];
    }

    return [];
  }, [page]);

  return (
    <header className={`${scroll ? "scroll" : ""}`}>
      <Navbar expand="lg">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" />
        </Link>
        <Navbar.Toggle onClick={handleTogglerClick}>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav-dropdown">
          <ul className="navbar-nav">
            {navbarLinks.length &&
              navbarLinks.map(({ title, href, target }, index) => (
                <li className="nav-item" key={index}>
                  <a
                    href={href}
                    className="nav-link"
                    target={target || "_self"}
                    rel={target === "_blank" ? "noopener noreferrer" : undefined}
                    onClick={handleNavbarClose}
                  >
                    {title}
                  </a>
                </li>
              ))}
          </ul>
          <ul className="navbar-socials">
            <li>
              <a href="https://x.com/0xtools_" target="_blank" rel="noreferrer">
                <img src={xIcon} alt="Twitter Icon" />
              </a>
            </li>
            <li>
              <a href="https://t.me/OxToolserc" target="_blank" rel="noreferrer">
                <img src={telegramIcon} alt="Telegram Icon" />
              </a>
            </li>
          </ul>
          <div className="navbar-cta">
            <a
              href="https://t.me/Oxtools1_bot"
              className="btn-main"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text">Access Bot</span>
            </a>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
