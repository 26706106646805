import React from "react";
import { Container, Row } from "react-bootstrap";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import heroShape from "../../../assets/img/main/hero/main-hero-img.png";
import heroBg from "../../../assets/img/main/hero/main-hero-bg.svg";
import heroBallOne from "../../../assets/img/main/hero/main-hero-ball-1.png";
import heroBallTwo from "../../../assets/img/main/hero/main-hero-ball-2.png";
import heroBallThree from "../../../assets/img/main/hero/main-hero-ball-3.png";
import heroBallFour from "../../../assets/img/main/hero/main-hero-ball-4.png";
import heroScroll from "../../../assets/img/general/scroll-down-icon.svg";

const Hero = ({ isMdScreen }) => {
  return (
    <ParallaxProvider>
      <section id="main-hero" className="main-hero-section">
        <div className="bg-image-wrap">
          <img src={heroBg} alt="Bg" />
        </div>
        <Container>
          <Row>
            <div className="balls-wrap">
              <Parallax
                speed={isMdScreen ? -5 : -10}
                className="parallax ball ball-1"
              >
                <img src={heroBallOne} alt="Ball" />
              </Parallax>
              <Parallax
                speed={isMdScreen ? 3 : 6}
                className="parallax ball ball-2"
              >
                <img src={heroBallTwo} alt="Ball" />
              </Parallax>
              <Parallax
                speed={isMdScreen ? -4 : -8}
                className="parallax ball ball-3"
              >
                <img src={heroBallThree} alt="Ball" />
              </Parallax>
              <Parallax
                speed={isMdScreen ? -2 : -4}
                className="parallax ball ball-4"
              >
                <img src={heroBallFour} alt="Ball" />
              </Parallax>
            </div>
            <div className="img-wrap">
              <Parallax
                speed={isMdScreen ? 8 : 16}
                className="parallax img-wrap"
              >
                <img src={heroShape} alt="Shape" />
              </Parallax>
            </div>
            <div className="text-wrap">
              <h1>An Innovative Comprehensive Telegram Tool</h1>
              <div className="question-wrap">
                <h5>
                  Why
                  <br /> 0xTools?
                </h5>
              </div>
              <div className="answer-wrap">
                <p>
                  0xTools aims to become the number one platform for Telegram
                  tools to facilitate trading and research in DeFi space
                </p>
                <a href="https://t.me/OxToolserc" className="btn-main btn-gradient" target="_blank" rel="noreferrer">
                  <span className="bg"></span>
                  <span className="text">Join Us</span>
                </a>
              </div>
            </div>
            <Parallax
              speed={isMdScreen ? -3 : -6}
              className="scroll-icon-wrap parallax"
            >
              <img src={heroScroll} alt="Scroll" />
            </Parallax>
          </Row>
        </Container>
      </section>
    </ParallaxProvider>
  );
};

export default Hero;
