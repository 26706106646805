const Lines = () => {
    return (
        <section id="lines" className="lines-section">
            <div className="lines-wrap">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    );
}

export default Lines;
