import React from "react";
import { Container, Row } from "react-bootstrap";

import utilitiesBg from "../../../assets/img/main/utilities/main-utilities-bg.svg";

const Utilities = () => {
  return (
    <section id="main-utilities" className="main-utilities-section">
      <div className="bg-image-wrap">
        <img src={utilitiesBg} alt="Bg" />
      </div>
      <Container>
        <Row>
          <div className="headline-wrap">
            <h2>Our Utilities</h2>
          </div>
          <div className="items-wrap">
            <div className="item item-white">
              <h4>Trading</h4>
              <ul>
                <li>0xT Swap</li>
                <li>0xT Transfers</li>
                <li>0xT Bridge</li>
                <li>0xT TradeTracking</li>
              </ul>
              <a
                href="https://t.me/Oxtools1_bot"
                className="btn-main"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text">Get started</span>
              </a>
            </div>
            <div className="item item-gradient">
              <h4>Tools</h4>
              <ul>
                <li>0xT Spy</li>
                <li>0xT Sniper</li>
                <li>0xT Buyers</li>
                <li>0xT Scanner</li>
                <li>0xT Signals</li>
              </ul>
              <a href="https://t.me/Oxtools1_bot" className="btn-main btn-gradient" target="_blank" rel="noreferrer">
                <span className="bg"></span>
                <span className="text">Get started</span>
              </a>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Utilities;
